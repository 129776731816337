<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="retailerMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
             <feather-icon icon="SkipBackIcon" /> {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6"> 
            <b-form-group :label="$t('Retailer Master List.Retailer Name')"> 
              <validation-provider
                #default="{ errors }"
                name="Retailer Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.retailer_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Retailer Master List.Retailer Name')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Retailer Master List.Retailer Code')">
              <validation-provider
                #default="{ errors }"
                name="Retailer Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.retailer_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Retailer Master List.Retailer Code')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group :label="$t('Retailer Master List.Retailer URL')">
              <validation-provider
                #default="{ errors }"
                name="Retailer URL"
                rules=""
              >
                <b-form-textarea
                  v-model="form.retailer_url"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Retailer Master List.Retailer URL')"
                  :disabled="!$can('write', 'masters')"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> 
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="saveRetailer"
            > 
              {{ $t("Common.Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import MasterServices from "@/apiServices/MasterServices";

export default {
  name: "form",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      retailer_id: null,
      form: {
        retailer_name: "",
        retailer_code: "",
        retailer_url: "",
        retailer_is_active: true,
      },
      required,
    };
  },
  methods: {
    saveRetailer() {
      this.$refs.retailerMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.retailer_id = this.retailer_id;
            // console.log("this.form=>",this.form);return false;
            const retailerMaster = await MasterServices.saveRetailer(
              this.form
            );

            if (retailerMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    retailerMaster.data.message || "Retailer Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/masters/retailer-list");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: retailerMaster.data.message || "Cannot Added Retailer",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveRetailer ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveRetailer",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleRetailer(retailer_id) {
      try {
        const response = await MasterServices.getSingleRetailer(retailer_id);
        let singleRetailer = response.data.data;
        if (response.data.status) {
          this.form = {
            retailer_name: singleRetailer.retailer_name,
            retailer_code: singleRetailer.retailer_code,
            retailer_url: singleRetailer.retailer_url,
            retailer_is_active: singleRetailer.retailer_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    if (
      this.$route.params.retailer_id &&
      !isNaN(this.$route.params.retailer_id)
    ) {
      this.retailer_id = this.$route.params.retailer_id;
      this.getSingleRetailer(this.retailer_id);
    }
  },
};
</script>

<style></style>
